import React from 'react';
import { Link } from 'gatsby';

import { Page } from 'src/components';

const CGUS = () => (
  <Page>
    <Page.Main className="container pt-24 space-y-12">
      <header>
        <h1 className="p-4 text-2xl font-bold text-center border-2 lg:text-3xl border-primary-light text-primary-light">
          Conditions Générales d’Utilisation du service Sharecare Awareness and Compliance
          Verification
        </h1>
        <p className="pt-6 text-center">
          Les présentes Conditions Générales d’Utilisation (ci-après les « CGU ») ont pour objet de
          définir les conditions d&apos;accès et d&apos;utilisation du Service proposé par Sharecare
          à ses clients dans le cadre de programmes de sensibilisation, de formation et/ou
          d’amélioration continue des compétences et activités concernées par un référentiel ou une
          norme donnée. Elles définissent également les obligations réciproques de chaque partie
          dans le cadre de l’utilisation du Service.
        </p>
      </header>
      <div>
        <h2 className="text-lg text-primary-light">Définitions</h2>
        <div className="pt-4 space-y-4">
          <p>
            <strong>Client :</strong> Désigne l’organisme, l’entreprise, l’établissement adhérent au
            Service Sharecare et qui le met à disposition de ses Utilisateurs.
          </p>
          <p>
            <strong>Service :</strong> Le Service en ligne Sharecare que le Client met à disposition
            de ses Utilisateurs en leur en communiquant l’URL.
          </p>
          <p>
            <strong>Utilisateur :</strong> Est défini comme « Utilisateur » du Service toute
            personne physique se connectant à l’URL du service.
          </p>
          <p>
            <strong>Plateforme :</strong> Ensemble des outils informatiques mis à disposition des
            Utilisateurs par Sharecare dans le cadre du Service.
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">
          1. Objet du Service et champ d’application des présentes Conditions Générales
          d’Utilisation
        </span>
        <div className="pt-4 space-y-4">
          <p>
            Les présentes conditions générales d&apos;utilisation (ci-après les « CGU ») ont pour
            objet de définir les conditions dans lesquelles Sharecare met la Plateforme à
            disposition du Client.
          </p>
          <p>
            En conséquence, le Client accepte, sans réserve, les présentes CGU dans leur intégralité
            avant toute utilisation du Service.
          </p>
          <p>
            La simple navigation sur la Plateforme ou utilisation du Service avant même la création
            d’un Profil Utilisateur ou la commande d’une Prestation, emporte automatiquement
            acceptation du Client aux présentes CGU. Sharecare se réserve la faculté de modifier les
            présentes CGU dans les conditions indiquées ci-après.
          </p>
          <p>
            Si le Client n’accepte pas ces conditions, il doit renoncer à toute utilisation de la
            Plateforme et du Service.
          </p>
          <p>Sharecare fournit pour le compte du Client le Service lui permettant :</p>
          <ul className="space-y-2 list-disc list-inside">
            <li>
              d’informer, former ou sensibiliser ses Utilisateurs à un référentiel donné (protocole
              ad hoc, norme, référentiel de bonnes pratiques défini) ;
            </li>
            <li>
              de vérifier régulièrement leur compréhension dudit référentiel et sa mise en pratique
              effective au quotidien ;
            </li>
            <li>
              de recueillir et de signaler ces données relatives à la conformité des usages des
              Utilisateurs conformément au référentiel sous un format structuré ;
            </li>
            <li>
              d&apos;organiser ces données et de les restituer à des fins d&apos;information, de
              pilotage et d’amélioration continue ;
            </li>
            <li>de soutenir une démarche de labellisation ou de certification, le cas échéant.</li>
          </ul>
          <p>
            Le Service est utilisé par le Client dans le cadre de ses procédures internes
            communiquées à l’Utilisateur au préalable.
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">2. Accès au service</span>
        <div className="pt-4 space-y-4">
          <p>
            Le Service est accessible depuis les appareils électroniques suivants, mobiles ou non :
            smartphone, ordinateur, tablette, sous réserve de leur compatibilité avec le Service.
          </p>
          <p>L’accès au Service se fait via une URL communiquée à l’Utilisateur par le Client.</p>
          <p>
            L’accès au Service se fait avec un compte Utilisateur fourni par le Client et des
            identifiants personnels fourni par le Service.
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">3. Disponibilité du Service</span>
        <div className="pt-4 space-y-4">
          <p>
            Le Service est normalement accessible 24 heures sur 24 et 7 jours sur 7, à l’exception
            des cas de force majeure, de difficultés techniques ou d’actions de maintenance. Ni
            Sharecare France ni le Client ne sera responsable d’un quelconque dommage directement
            lié à un cas de force majeure tel que défini par l’article 1218 du code civil (y compris
            l&apos;interruption, la suspension, la réduction ou les coupures d’électricité ou de
            réseaux de télécommunications) que la partie défaillante notifiera à l’autre partie sans
            délai et fera tout son possible de limiter. Le Client est invité à prévoir une
            alternative à ce service en cas de besoin.
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">4. Utilisation du service</span>
        <div className="pt-4 space-y-4">
          <p>
            Le Service est mis en place par le Client sous ses seuls contrôles, direction et sous sa
            seule responsabilité dans le cadre des protocoles en place au sein de ses établissements
            et en complément de ceux-ci.
          </p>
          <p>
            Le Service mixe la présentation de normes ou de bonnes pratiques, des questions pour
            évaluer leur compréhension et leur mise en œuvre puis des messages motivationnels
            (information, action à prendre, etc.)
          </p>
          <p>
            L’outil repose sur un processus purement déclaratif. Pour garantir la cohérence du
            résultat, il est nécessaire que l’Utilisateur réponde en toute bonne foi à l’ensemble
            des questions.
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">
          5. Modifications du Service et des CGU – Suspension ou arrêt définitif de la Plateforme –
          Durée – Résiliation
        </span>
        <div className="pt-4 space-y-4">
          <p>
            Sharecare peut être amené à tout moment à procéder à des modifications du Service et des
            présentes CGU de façon unilatérale, moyennant un préavis d’un (1) mois, ce que le Client
            reconnaît et accepte expressément. Le Client sera informé de toute modification, par
            tout moyen à la convenance de Sharecare.
          </p>
          <p>
            En cas d’une telle modification, les conditions générales applicables restent celles qui
            étaient en vigueur au jour de la commande d’une Prestation.
          </p>
          <p>Le Client pourra refuser ces modifications en renonçant à l’utilisation du Service.</p>
          <p>
            Les CGU entrent en vigueur à compter de leur acceptation par le Client, c’est-à-dire
            lors de la création de son Profil Utilisateur, et ce pour une durée indéterminée.
          </p>
          <p>
            Enfin, Sharecare se réserve le droit de suspendre le Service pour des raisons
            d’intervention de maintenance sur la Plateforme notamment ou de bloquer l’accès à
            certains Utilisateurs en cas de défaillance dans l’authentification ou d’évènement que
            Sharecare considère comme anormal dans l’utilisation du Service.
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">
          6. Gestion des données Utilisateurs
        </span>
        <div className="pt-4 space-y-4">
          <p>
            Sharecare apporte la plus grande importance à la confidentialité, ainsi qu’à la sécurité
            de l’ensemble des informations et des données personnelles collectées des
            Utilisateurs/Clients. Il s&apos;engage à traiter les données personnelles pour le compte
            du Client conformément aux dispositions de la loi Informatique et Libertés du 6 janvier
            1978 modifiée, ainsi que du Règlement Général à la Protection des Données (RGPD) du 27
            avril 2016.
          </p>
          <p>
            Dans le cadre de ce Service mis en place par le Client pour ses Utilisateurs, notre
            politique de protection des données personnelles applicable est complémentaire à celle
            du Client.
          </p>
          <p>
            Sharecare, en sa qualité de sous-traitant, est amené à traiter les données personnelles
            des Utilisateurs pour les besoins de la fourniture du Service et en particulier pour la
            gestion technique des comptes des Utilisateurs et des Prestations souscrites. Ces
            traitements ont pour fondement juridique la nécessité d&apos;exécution contractuelle
            (article 6.1.b. du RGPD, relation contractuelle, ainsi que la fourniture des services
            demandés).
          </p>
          <p>
            Ce traitement est nécessaire à l’exécution des présentes CGU, auxquelles
            l&apos;Utilisateur est Partie.
          </p>
          <p>
            Ces données sont destinées au personnel habilité de Sharecare, ainsi qu’à des tiers
            hébergeant et/ou assurant le support la Plateforme et/ou de manière générale à tous
            Fournisseurs intervenant sur la Plateforme afin de permettre à Sharecare de fournir le
            Service. Sharecare ne transfère pas les données hors de l’Union Européenne. Les données
            du Client sont conservées pendant la durée des CGU, augmentée des délais de prescription
            applicables.
          </p>
          <p>
            L’Utilisateur dispose d’un droit d’accès, de rectification et d’effacement, de
            limitation du traitement de ses données, d’un droit d’opposition au traitement de ses
            données qu’il peut exercer auprès de son Responsable de Traitement. En cas de
            difficulté, il est possible de s&apos;adresser directement à Sharecare par email ou
            courrier aux adresses suivantes :
          </p>
          <ul className="space-y-2 list-disc list-inside">
            <li>
              Courriel au DPO à{' '}
              <a href="mailto:dpo@sharecare.com" className="underline">
                dpo@sharecare.com
              </a>{' '}
              ;
            </li>
            <li>
              Courrier postal à l’attention de SHARECARE, 105 avenue Raymond Poincaré 75116 Paris.
            </li>
          </ul>
          <p>
            Pour toute information complémentaire, nous vous invitons à consulter notre{' '}
            <Link to="/rgpd" className="underline">
              Politique de protection des données.
            </Link>
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">7. Responsabilité</span>
        <div className="pt-4 space-y-4">
          <p>
            Sharecare ne pourra être tenu responsable de tout dommage matériel ou immatériel
            résultant de la consultation, l&apos;utilisation ou l&apos;interprétation des
            informations fournies.
          </p>
          <p>
            Comme pour tout processus d’évaluation, de notation ou de vérification, le Service
            Sharecare implique, dans une certaine mesure, une combinaison de données collectées à
            l&apos;aide de critères objectifs et une évaluation subjective. Les informations
            fournies dans le cadre du Service sont destinées à être prises en compte dans le cadre
            d&apos;une approche holistique de la prise de décision, lors de l&apos;évaluation de
            plusieurs options disponibles. Ces informations sont destinées à être considérées en
            combinaison avec toutes les autres sources d&apos;informations raisonnablement
            pertinentes et disponibles, et ne doivent pas être considérées comme un seul facteur
            dans la prise de décision.
          </p>
          <p>
            Le Service est fourni, comme indiqué dans les présentes pour, d’une part, permettre une
            meilleure information et compréhension par les Utilisateurs des processus, procédures et
            contrôles mis en place par le Client, ainsi que de la mise en œuvre et de l’efficacité
            de ces processus, procédures et contrôles et pour, d’autre part, renforcer la
            responsabilisation et l’engagement des Utilisateurs au respect de ces processus,
            procédures et contrôles.
          </p>
          <p>
            Par conséquent, le Service fourni ne saurait être reconnu comme étant de nature à
            empêcher la survenance d’événements, de circonstances auxquels les Utilisateurs
            pourraient être confrontés ou exposés.
          </p>
          <p>
            Les liens présents dans le Service peuvent diriger vers des sites tiers dont le contenu
            ne peut en aucune manière engager la responsabilité de Sharecare France.
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">
          8. Médiation - Règlement des litiges
        </span>
        <div className="pt-4 space-y-4">
          <ul className="space-y-2 list-disc list-inside">
            <li>
              Courrier postal : à l’attention de Sharecare Direction Générale, 105 avenue Raymond
              Poincaré 75116 Paris.
            </li>
            <li>
              Adresse courriel :{' '}
              <a href="mailto:support.france@sharecare.com" className="underline">
                support.france@sharecare.com.
              </a>
            </li>
            <li>
              Formulaire de contact de la Plateforme :{' '}
              <a href="mailto:support.france@sharecare.com" className="underline">
                support.france@sharecare.com.
              </a>
            </li>
          </ul>
          <p>
            De manière générale, le Client a la possibilité de saisir les juridictions compétentes
            en cas de différend relatif à l’interprétation, la conclusion ou l’exécution des
            présentes CGU.
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">10. Loi applicable</span>
        <div className="pt-4 space-y-4">
          <p>
            Les présentes CGU sont rédigées en langue française et sont soumises au droit français.
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">11. Loi applicable</span>
        <div className="pt-4 space-y-4">
          <p>
            Pour toute question concernant l’utilisation du Service mis à disposition par le Client,
            merci de contacter :{' '}
            <a href="mailto:support.france@sharecare.com" className="underline">
              support.france@sharecare.com.
            </a>
          </p>
          <p>
            Pour toute question ou demande relative au traitement des données par Sharecare, merci
            de contacter :{' '}
            <a href="mailto:dpo@sharecare.com" className="underline">
              dpo@sharecare.com.
            </a>
          </p>
        </div>
      </div>
    </Page.Main>
  </Page>
);

export default CGUS;
